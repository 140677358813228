<template>
	<section class="team gray-bg min-height-80vh">
        <div class="container">
            <div class="breadcrump">
                <h4 class="py-3 mb-4"><span class="text-muted fw-light"><a href="/">Bosh sahifa</a> /</span> Xizmatlar</h4>
            </div>
            <div class="section-title text-center">
                <p>Xizmatlar</p>
                <h2 class="mb-4"></h2>
            </div>
        </div>
    </section>
</template>

<script>
export default {
	methods: {
	},
	mounted() {
	}
}
</script>